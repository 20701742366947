<template>
  <div>
    <title>SIM INVENTORY ~ EDIT PRODUCT LIST DATA PROCESS DETAILS</title>
    <section class="content-header">
      <h1>
       Edit Product List Data Process Detail
        <br />
        <h4>Please update the transaction data for the detailed process product list</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Transaction Product List Process Detail</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">Product List Data Process Detail</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <div class="box-body">
              <div class="row">
                <div class="col-xs-3">
                  <label>Product Number</label>
                </div>

                <div class="col-xs-1">
                  <label>:</label>
                </div>
                <div class="col-xs-7">
                  <input
                    type="text"
                    class="form-control"
                    readonly
                    name="produkno"
                    v-model="produkno"
                    id="produkno"
                  />
                </div>
              </div>
            </div>
            <form role="form">
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-9">
                    <label for="exampleInputEmail1">Process Code</label>
                    <input
                      type="hidden"
                      v-model="idxprodukdproses"
                      autocomplete="off"
                      class="form-control"
                    />
                    <input
                      type="hidden"
                      v-model="idxprodukd"
                      autocomplete="off"
                      class="form-control"
                    />
                    <input
                      type="text"
                      v-model="kodeproses"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Kode Proses"
                    />
                  </div>
                  <div class="col-xs-3">
                    <label for="exampleInputEmail1">Action</label>
                    <br />
                    <button
                      type="button"
                      @click="getproses()"
                      class="btn btn-info"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <br />
                <div class="form-group">
                  <label for="exampleInputEmail1">Process Name </label>
                  <input
                    type="text"
                    readonly
                    v-model="prosesnm"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="catatanp"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes"
                  />
                </div>
              </div>
              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                > <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <!-- <router-link to="/produk">
                  <button class="btn btn-success">Kembali</button>
                </router-link> -->
              </div>
            </form>
          </div>
        </div>
        
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addprosesdprod",
  data() {
    return {
      loading: false,
      idxprodukdproses: "",
      idxprodukd: "",
      kodeproses: "",
      prosesnm: "",
      catatanp: ""
    };
  },
  created() {
    this.fetchdata();
  },
  methods: {
    async fetchdata() {
      this.loading = true;
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/produk_d_proses/getproduk_d_prosesbyid?id=" + this.$route.params.id;
      const urlAPIget =
        this.$apiurl +
        "produk_d_proses/getproduk_d_prosesbyid?id=" +
        this.$route.params.id;

      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.idxprodukdproses = resp.data.data.id_produk_d;
          this.idxprodukd = resp.data.data.id;
          this.kodeproses = resp.data.data.kode_proses;
          this.catatanp = resp.data.data.notes;
          this.produkno = sessionStorage.getItem("noproduk");
          const urlAPIGETl =
            this.$apiurl +
            "daftar_proses/getdaftar_prosesbykode?kode_proses=" +
            this.kodeproses;
          axios.get(urlAPIGETl, { headers }).then((respn) => {
            if (respn.data.pesan == "Data not found") {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Sorry, process not found",
                showConfirmButton: false
              });
              this.loading = false;
            } else {
              this.prosesnm = respn.data.data.nama_proses;
            }
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    async updateData() {
      this.loading = true;
      var idxproddp = this.idxprodukdproses;
      var idxprodd = this.idxprodukd;
      var kodep = this.kodeproses;
      var namaproses = this.prosesnm;
      var ctnp = this.catatanp;
      if (kodep == "" || namaproses == "" || idxproddp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Process can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (ctnp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdatadetail = {
          id_produk_d: this.idxprodukd,
          kode_proses: this.kodeproses,
          notes: this.catatanp,
          kode_user: kodeuser
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        // const urlAPIUpdateDatadetail = "http://26.183.23.191/inventory/backend/inventory/api/produk_d_proses/updateproduk_d_proses/" + idx;
        const urlAPIUpdateDatadetail =
          this.$apiurl + "produk_d_proses/updateproduk_d_proses/" + idxprodd;
        axios
          .put(urlAPIUpdateDatadetail, paramdatadetail, { headers: headers })
          .then((respn) => {
            console.log(respn);
            console.log(urlAPIUpdateDatadetail);
            console.log(paramdatadetail);
            if (respn.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
